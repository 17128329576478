<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户信息</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">账号：</div>
                <el-input v-model="search.loginName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">手机号：</div>
                <el-input type="number" maxlength="11" v-model="search.phone" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="rewardAllocationAdd" @click="goAdd">新增签到奖励</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="loginName" label="账号"></el-table-column>
            <el-table-column align="center" prop="nickName" label="昵称"></el-table-column>
            <el-table-column align="center" prop="birthday" label="生日"></el-table-column>
            <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
            <el-table-column align="center" prop="gender" label="性别">
              <template slot-scope="scope">
                <span>{{scope.row.gender | getGender}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="integral" label="积分"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        title="详情"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm" label-position="right" label-width="100px">
        <el-form-item label="头像：">
          <el-avatar shape="square" :size="50" :src="upData.avatar"></el-avatar>
        </el-form-item>
        <el-form-item label="账号：">{{ upData.loginName }}</el-form-item>
        <el-form-item label="昵称：">{{ upData.nickName }}</el-form-item>
        <el-form-item label="性别：">{{ upData.gender | getGender }}</el-form-item>
        <el-form-item label="手机号：">{{ upData.phone }}</el-form-item>
        <el-form-item label="生日：">{{ upData.birthday }}</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    const validateExtraBonus = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入额外奖励'));
      } else {
        callback();
      }
    };
    const validateRewardPoints = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入奖励积分'));
      } else {
        callback();
      }
    };
    const validateSignDay = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入签到天数'));
      } else {
        callback();
      }
    };
    return {
      search: {
        loginName: '',
        phone: '',
      },
      type: "",
      tableList: [],
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        rewardPoints: 0,
        signDay: 0,
        extraBonus: 0
      },
      genderAll: [
        {label: '保密', value: 0},
        {label: '男', value: 1},
        {label: '女', value: 2},
      ],
      rules: {
        extraBonus: [{required: true, validator: validateExtraBonus, trigger: "blur"}],
        rewardPoints: [{required: true, validator: validateRewardPoints, trigger: "blur"}],
        signDay: [{required: true, validator: validateSignDay, trigger: "blur"}],
      },
      rewardAllocationAdd: false,
      rewardAllocationUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  filters: {
    getGender(value) {
      const data = _this.genderAll.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData[key] = filterData[key];
        }
      }

      _this.$api
          .getUserList(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "rewardAllocation:add") {
          _this.rewardAllocationAdd = true;
        }
        if (v == "rewardAllocation:upd") {
          _this.rewardAllocationUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },

    handleClose() {
      let _this = this;
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        loginName: '',
        phone: '',
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        rewardPoints: 0,
        signDay: 0,
        extraBonus: 0
      }
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}

.el-input-number {
  width: 100%;

  /deep/ .el-input__inner {
    text-align: left;
  }
}

.goods-box {
  margin-bottom: 20px;

  .search-title {
    width: 100px;
  }
}
</style>
